<template>
  <div class="auth">
    <div class="container row auth__content">
      <div class="auth__left col">
        <div class="auth__inner">
          <img class="auth__logo" src="@/assets/png/logo.png" alt="" @click="$router.push('/')" />
          <!-- <h1 class="auth__title main-title">
                        {{$route.params.type === 'authorization' ? 'С возвращением Вас!' : 'Добро пожаловать!'}}
                    </h1> -->
          <!-- <p class="auth__text main-text">
            {{
              $route.params.type === "authorization"
                ? "Пожалуйста введите свои данные для входа."
                : "Пожалуйста введите свои данные."
            }}
          </p> -->
          <component :is="block" class="auth__block" />
          <div v-if="$route.params.type != 'registration-addition'" class="auth__info flex">
            <!--                        <label class="auth__label">-->
            <!--                            {{$route.params.type === 'authorization' ? 'Нет аккаунта?' : 'У вас уже есть аккаунт?'}}-->
            <!--                        </label>-->
            <!--                        <router-link class="auth__link" :to="$route.params.type === 'registration' ? '/auth/authorization' : '/auth/registration'">-->
            <!--                            {{$route.params.type === 'authorization' ? 'Зарегистрируйтесь' : 'Войдите'}}-->
            <!--                        </router-link>-->
          </div>
        </div>
      </div>
      <div class="auth__right col" />
    </div>
  </div>
</template>

<script>
export default {
  components: {
    AuthorizationBlock: () => ({
      component: import("./components/authorization"),
    }),
    RegistrationBlock: () => ({
      component: import("./components/registration"),
    }),
    RegistrationAdditionBlock: () => ({
      component: import("./components/registrationAddition"),
    }),
    PasswordForgotBlock: () => ({
      component: import("./components/passwordForgot"),
    }),
  },
  computed: {
    block() {
      if (this.$route.name === "auth") {
        return this.$route.params.type + "-block";
      } else {
        return "authorization-block";
      }
    },
  },
  mounted() {
    if (this.$route.name === "Auth") {
      this.$router.push("/auth/authorization");
    }
  },
};
</script>

<style scoped>
.auth {
  height: 100vh;
  overflow-y: auto;
  background: #fff;
}
.auth__content {
  height: 100%;
}
.auth__left,
.auth__inner {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
}
.auth__inner {
  width: 85%;
}
.auth__logo {
  max-width: 200px;
}
.auth__logo,
.auth__info {
  margin: auto;
}
.auth__title {
  margin: 40px 0 10px;
  width: 100%;
}
.auth__right {
  min-height: 700px;
  background-image: url(../../assets/png/auth-bg.png);
  background-repeat: repeat;
  background-position: center center;
  background-size: 100%;
}
.auth__block {
  align-items: flex-start;
}
@media (max-width: 1024px) {
  .auth__right {
    background-repeat: no-repeat;
  }
  .auth__title {
    font-size: 32px;
  }
}
</style>
